<template>
  <div class="story">
    <BannerKv :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic" />
    <!-- <StorySearch/> -->
    <StoryList :showArticle="showArticle" />
    <!-- <Pagination/> -->
    <pagination :data="showArticle"
      :item="pagination"
      @pass-val="changeCurrentPage"></pagination>
  </div>
</template>

<script>
import '@/assets/scss/story.scss';
import BannerKv from '@/components/BannerKv.vue';
// import Pagination from '@/components/Pagination.vue';
// import StorySearch from '@/components/story/StorySearch.vue';
import StoryList from '@/components/story/StoryList.vue';
import pagination from '@/components/Paginate.vue';
import { getArticlePages } from '@/lib/cakeHttp';
import { mapState } from 'vuex';
// import { getPageStories } from '@/lib/public';
// import { mapMutations, mapGetters } from 'vuex';
import { getUrlLangCode } from '@/utils/getLangCode';

// import m01 from '@/assets/img/team/IVF_Center_team/1/1.png';
// import m02 from '@/assets/img/team/IVF_Center_team/1/2.png';
// import m05 from '@/assets/img/team/IVF_Center_team/1/5.png';
// import m20 from '@/assets/img/team/TCM_Center_team/1/1.png';

import pic01 from '@/assets/img/index/story_kv.jpg';

export default {
  name: 'Story',
  components: {
    BannerKv,
    // StorySearch,
    StoryList,
    // Pagination,
    pagination
  },
  props: {
    page: String
  },
  data() {
    return {
      BannerKvData: {
        titleEn: 'NUWA BABY',
        titleZh: this.$t('nuwaStories'),
        backgroundPic: pic01
      },
      showArticle: [],
      pagination: {
        currentPageCount: 1,
        totalPageCount: 1
      }
    };
  },
  computed: {
    ...mapState(['lang'])
  },
  methods: {
    changeCurrentPage(val) {
      this.pagination.currentPageCount = val;
      localStorage.setItem('page', val);
      this.showArticle = [];
      this.$router.push(`p${val}`);
      this.getData(val);
    },
    getData(page) {
      getArticlePages(
        {
          page,
          lang_id: this.lang,
          type: 'stories',
          limit: 9
        },
        ({ data, pageCount }) => {
          this.showArticle = data.map(({ id, slug, cover_path, description, title, content }) => ({
            id,
            title,
            context: content,
            path: `${getUrlLangCode()}/storyarticle/${slug}`,
            pic: cover_path,
            date: null,
            name: description
          }));
          this.pagination = {
            currentPageCount: page,
            totalPageCount: pageCount
          };
        },
        (message) => {
          if (message === '查無資料' && page !== 1) {
            this.$router.replace({ params: { page: 'p1' } });
          }
        }
      );
    }
  },
  watch: {
    $route(to) {
      this.getData(+to.params.page?.replace(/^\D/, ''));
    }
  },
  mounted() {
    this.getData(+this.page?.replace(/^\D/, ''));
  }
};
</script>
