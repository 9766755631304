<template>
  <section id="story_list">
    <div class="w1200 container">
      <div class="storybox">
        <a
          class="item"
          v-for="(item, key) in showArticle"
          :key="key"
          @click="recordStoryComeFromPath(item.path)"
          >
          <!-- :to="`/${item.path}`" -->
          <div class="inner">
            <div class="item-cover">
              <p class="txt-regular ls2 more">{{ $t("readAritcal") }}</p>
            </div>
            <div class="img" :style="{ backgroundImage: `url(${item.pic})` }"></div>
            <p class="doctor_name">{{ item.name }}</p>
            <div class="item-container">
              <h6 class="txt-medium">{{ item.title }}</h6>
              <p class="context small txt-dark_gray" v-html="item.context"></p>
            </div>
          </div>
          <p class="small">{{ item.date }}</p>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import '@/assets/scss/story.scss';
import { getUrlLangCode } from '@/utils/getLangCode';
import { mapMutations } from 'vuex';

export default {
  name: 'StoryList',
  props: ['showArticle'],
  data() {
    return {
      getUrlLangCode
    };
  },
  methods: {
    ...mapMutations(['setStoryComeFromPath']),
    recordStoryComeFromPath(path) {
      this.setStoryComeFromPath(this.$route.path);
      this.$router.push(`/${path}`);
    }
  }
};
</script>
<style>
.context font {
  font: inherit;
}
.context img {
  display: none;
}
</style>
